@font-face {
  font-family: 'FortumSansWeb';
  src: local('FortumSansWeb'),
    url("'./../fonts/FortumSansWeb-Regular.woff2") format('woff2');
}

div[class^='MuiChip-root'] {
    color: #000;
    background-color: #FFFFFF;
}

.CatalogPageApplications th:nth-child(1),
.CatalogPageApplications th:nth-child(2) {
  width: 15% !important;
  min-width: 15% !important;
}

.CatalogPageApplications th:nth-child(3),
.CatalogPageApplications th:nth-child(4) {
  width: 30% !important;
  min-width: 30% !important;
}

.CatalogPageApplications div[class^="MuiGrid-root-"] {
  max-width: 100% !important;
  flex-basis: 100% !important;
}
